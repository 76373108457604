import {List, Image, Card, Button, Steps, PullToRefresh} from "antd-mobile";
import React, {useEffect, useRef, useState} from "react";
import {OrderDELETE, OrderGET, OrderGETDelivery} from "../../model/user";
import {useNavigate, useParams} from "react-router-dom";
import {billPlan, SkeletonDIV} from "../../utils";
import {BillPlanPATCH} from "../../model/store";
import {OrderPlanPACT} from "../../model/delivery";

const {Step} = Steps

function GoodsImg(props) {
    return <Image
        src={props.url}
        style={{borderRadius: 3}}
        fit='cover'
        width={30}
        height={30}
    />
}



export default function () {

    const navigate = useNavigate();
    const [order, setOrder] = useState([])
    const [orderB, setOrderB] = useState([])
    const [orderC, setOrderC] = useState([])
    const [orderD, setOrderD] = useState([])
    const [load,setLoad] = useState(1)
    const params = useParams()
    const rest = useRef(true)
    useEffect(() => {
        if (rest.current){
            if (params.plan == 'a') {
                OrderGET(0).then(async r => {
                    let data = await r.json()
                    setOrder(data)
                    console.log('未支付',data)
                })
            }
            if(params.plan == 'b'){
                OrderGETDelivery(2).then(async r => {
                    setOrderB(await r.json())
                })
            }
            if(params.plan == 'c'){
                OrderGETDelivery(1).then(async r => {
                    let data = await r.json()
                    console.log('orderC'+ data)
                    setOrderC(data)

                })
            }
            // 全部订单
            if(params.plan == 'd'){
                OrderGET(4).then(async r=>{
                    let data = await  r.json()
                    console.log('全部订单',data)
                    setOrderD(data)
                })
            }

            rest.current = false
            return;
        }

    }, [load])
    return <PullToRefresh className='card' onRefresh={()=>setLoad(load+1)}>
        {order.length ? order.map(item => <Card key={item._id} className="cardOrder" title={'单号:' + item.code} >
            {item.bill.map(bi => <List header={`${bi.store}-${bi.code}`}>
                {bi.goods.map(goods => <List.Item prefix={<GoodsImg url={goods.img}/>}
                                                  description={goods.text + '¥ ' + goods.count}
                                                  children={goods.title} extra={goods.money + " ¥"}/>
                )}
            </List>)}
            <List>
                <List.Item description={item.remark ? item.remark : "无"}
                           children={"备注"}/>
                <List.Item description={`${item.bill.length}家店- ${item.count}件商品- 共计 ${item.deliveryMoney}¥`}
                           children={"配送费: "} extra={`合计:${item.deliveryMoney+item.money} ¥ `}/>
                {item.depositText ? <List.Item description={"订单"}
                                               children={"骑手留言"}/> : ''}

            </List>
            <div className={'payBtn'}>
                <Button color='danger' style={{marginRight: 10}} fill='outline'
                        onClick={() => OrderDELETE(item._id).then(r => {
                            if (r.status == 200) {
                                setLoad(load+1)
                                OrderGET(0).then(async r => {
                                    let data = await r.json()
                                    setOrder(data)
                                    console.log('未支付',data)
                                })
                            }
                        })}>取消订单</Button>
                <Button color='primary' style={{marginRight: 10}} onClick={()=>navigate('/pay/'+item._id)} >修改订单</Button>
                <Button color='primary'  onClick={()=>navigate('/pay?order='+item._id)} >支付订单</Button>
            </div>
        </Card>) : ''}

        {/*自取订单*/}
        {orderB.length?orderB.map(item=> <Steps  direction='vertical' className={'orderBottom'}>
            {item.billArr.map(bi=> <Step
                title={`${bi.store}-[${bi.plan==3?'已自提':billPlan(bi.plan)}]` }
                status='finish'
                description={ <div>
                    <List >
                        {bi.goods.map(goods=><List.Item prefix={<GoodsImg url={goods.img} />} description={`${goods.text} ¥ * ${goods.count}`} children={goods.title} extra={goods.money+"¥"}/>)}
                         </List>
                    <h4>取件码: {bi.code} </h4>
                    <div className="orderBtn">
                        <Button  size='small'>其他</Button>
                        <Button  size='small'>联系店家</Button>
                        {bi.plan == 3?'':<Button size='small' color='primary' onClick={()=>{
                            console.log(11111)
                            BillPlanPATCH(bi._id, 3).then(async r =>{
                                setLoad(load+1)
                                console.log('取走商品',r.status)
                            })
                        }} >取走</Button>}
                    </div>
                </div>}
            />)}

            <Step
                title='备注'
                status='wait'
                description={item.remark}
            />
            <Step
                title='到店自取'
                status='wait'
                description={<div>
                    <Button block fill='outline' color='primary' onClick={()=>{
                        OrderPlanPACT(item._id,4).then(r=>{
                            if(r.status == 200){
                                setLoad(load+1)
                            }
                        })
                    }} >全部已取走</Button>
                </div>}
            />
        </Steps>):''}

        {/*//外卖订单*/}
        {orderC.length?orderC.map((item,i)=> <Steps  key={'orderc'+i} direction='vertical' className={'orderBottom'}>
            {item.billArr.map(bi=> <Step
                title={`${bi.store}-[${billPlan(bi.plan)}]` }
                status='finish'
                description={ <div>
                    <List >
                        {bi.goods.map((goods,i)=><List.Item key={'goods'+i} prefix={<GoodsImg url={goods.img} />} description={`${goods.text} ¥ * ${goods.count}`} children={goods.title} extra={goods.money+"¥"}/>)}
                    </List>
                    <div className="orderBtn">
                        <Button  size='small'>其他</Button>
                        <Button  size='small'>联系店家</Button>
                        {/*<Button size='small' color='primary'>退款</Button>*/}
                    </div>
                </div>}
            />)}

            <Step
                title='备注'
                status='wait'
                description={item.remark}
            />
            <Step
                title='外卖配送'
                status='wait'
                description={item.plan==1?<Button block fill='outline' color='primary' onClick={()=>{

                }} >等待接单</Button>:<Button block fill='outline' color='primary' onClick={()=>{
                    OrderPlanPACT(item._id,4).then(r=>{
                        if(r.status == 200){
                            setLoad(load+1)
                        }
                    })
                }}>全部签收</Button>}
            />
        </Steps>):''}

        {orderD.length?orderD.map((item)=><List>
            <List.Item description={`金额 :${item.money} ¥`}  children={`单号: ${item.code}`} extra={'已签收'}/>
        </List>):''}
    </PullToRefresh>
}