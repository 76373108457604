import {Image, List} from "antd-mobile";
import React, {useEffect, useState} from "react";
import {BillLiPLanGet} from "../../model/store";

export default function (){
    const [bill,setBIll] = useState([])
    useEffect(()=>{
        BillLiPLanGet(3).then(async r=>{
            let data = await r.json()
            console.log('流水单',data)
            setBIll(data)
        })
    },[])
    return <div>
        {bill.length?<List>
            {bill.map(item=>
                <List.Item description={item.goods.map(goods=> `${goods.text}¥ * ${goods.count} -`)} children={`单号:${item.code}`} extra={`已到账[${item.money}¥]`}/>)}
        </List>:''}

    </div>
}

function GoodsLi(){
    return <div className={'orderGoodsLi'}>
        <p>规格- 3份</p>
        <p>规格- 3份</p>
        <p>规格- 3份</p>
    </div>
}