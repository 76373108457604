import Goods from "../../component/goods";
import { useThrottleFn } from 'ahooks'
import {SideBar,SearchBar} from "antd-mobile";
import {useEffect, useRef, useState} from "react";
import {GoodsLi, StoreLi, UserMsg, UserScope} from "../../model/user";
import {SkeletonDIV, wx} from "../../utils";
import {useParams, useSearchParams} from "react-router-dom";
const Height = window.innerHeight-60

export  default  function (){
    const [store, setStore] = useState([]) //店铺列表
    const [goods,setGoods] = useState([])   //商品列表
    const [activeKey, setActiveKey] = useState(1)
    const firstRenderRef = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    function goodsData(id){
        GoodsLi(id).then(async g => {
            let goods = await g.json()
            console.log('默认店铺商品',goods)
            setGoods(goods)
        })

    }

    useEffect(()=>{
        if(firstRenderRef.current){
            let scope =  searchParams.get('scope')
            if(localStorage.getItem('token')){
                UserMsg().then(async r => {
                    if(r.status != 200){
                        localStorage.removeItem('token')
                        return
                    }
                    console.log('用户信息',await r.json())
                })
            }

            console.log('位置',scope)
            localStorage.setItem('scope',scope)
            StoreLi(scope?scope:0).then(async r => {
                let data = await r.json()
                setStore(data)
                console.log('店铺列表',data)
                if(data.length){
                    goodsData(data[0]._id)
                }
            })
            firstRenderRef.current = false;
            return;
        }
    },[])
    return <div className='Home' style={{height: Height}}>
        <div className='homeBar'>
            <SearchBar placeholder='请输入内容'/>
        </div>
        <div className='HomeGoods'>
            {store.length?<SideBar style={{'--item-border-radius': '0px', height: Height - 57}}
                                   onChange={(key)=>goodsData(key)}>
                {store.map(item => (
                    <SideBar.Item key={item._id} title={item.name} onC/>
                ))}
            </SideBar>:<SkeletonDIV/>}

            {goods.length ? <div className="homeRow" style={{height: Height - 60}}>
                {goods.map((obj, i) => <Goods key={"goods"+i} obj={obj}/>)}
                <div className={"goods"}></div>
            </div> : <SkeletonDIV/>}
        </div>

    </div>
}


// ssh -L 3001:localhost:3001 root@114.132.75.251
