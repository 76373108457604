import {Badge, Button, Card, Form, Image, List, PullToRefresh, Steps, Switch, Tabs, TextArea} from "antd-mobile";
import React, {useEffect, useRef, useState} from "react";
import {Step} from "antd-mobile/es/components/steps/step";
import {DeliveryGet, DeliveryOrderGEG, DeliveryPOST, DeliveryPUT, OrderPlanPACT} from "../../model/delivery";
import {billPlan} from "../../utils";
import {BillPlanPATCH} from "../../model/store";
const Height = window.innerHeight-120


export default function () {

    const [load, setLoad] = useState(1)
    const [order, setOrder] = useState([])    //订单
    const [orderB, setOrderB] = useState([])    //订单
    const ref = useRef(true)
    useEffect(() => {
        document.title = "骑手中心"
        DeliveryGet(localStorage.getItem('deliveryId')).then(async r => {
            let data = await r.json()
            console.log('可接单', data)
            setOrder(data)
        })
        DeliveryOrderGEG().then(async r => {
            let data = await r.json()
            console.log('在送单', data)
            setOrderB(data)
        })
    }, [load]);
    return <PullToRefresh onRefresh={() => setLoad(load + 1)}>
        <Tabs className={'orderCard'}>
            <Tabs.Tab
                title={<Badge content={order.length ? order.length : ''} style={{'--right': '-10px', '--top': '8px'}}>
                    新单
                </Badge>} key='fruits'>
                <div style={{height: Height}}>
                    {order.length ? order.map(item => <OrderA data={item} upData={() => {
                        setLoad(load + 1)
                    }}/>) : ''}
                </div>
            </Tabs.Tab>
            <Tabs.Tab
                title={<Badge content={orderB.length ? orderB.length : ''} style={{'--right': '-10px', '--top': '8px'}}>
                    配送
                </Badge>} key='vegetables'>
                <div style={{height: Height}}>
                    {orderB.length ? orderB.map(item => <OrderItem data={item} onBillPlan={(id) => {
                        BillPlanPATCH(id, 3).then(r => {
                            if (r.status == 200) {
                                setLoad(load + 1)
                            }
                        })
                    }} onOrderPlan={(id) => {
                        OrderPlanPACT(id, 3).then(r => {
                            if (r.status == 200) {
                                setLoad(load + 1)
                            }
                        })
                    }}/>) : ''}
                </div>
            </Tabs.Tab>
        </Tabs>
    </PullToRefresh>
}

function OrderItem(props) {
    const {data, onBillPlan, onOrderPlan} = props
    return <Steps direction='vertical' className={'orderBottom'}>
        <Step
            title={<h3>单号: {data.code}</h3>}
            status='finish'
            description='完成时间：2020-12-01 12:30'
        />
        {data.billArr.map(bi => <Step
            title={`${bi.store} [${billPlan(bi.plan)}]`}
            status='finish'
            description={<div>
                <List>
                    {bi.goods.map(goods => <List.Item prefix={<GoodsImg url={goods.img}/>}
                                                      description={goods.text+ + '¥ ' + goods.count}
                                                      children={goods.title} extra={goods.money + " ¥"}/>)}
                </List>
                <h4>取件码: {bi.code} </h4>
                <div className={'payBtn'}>
                    <Button size='small' style={{marginRight: 10}} fill='outline'>其他</Button>
                    <Button size='small' style={{marginRight: 10}} fill='outline'>联系商户</Button>
                    {bi.plan == 1 || bi.plan == 2 ?
                        <Button size='small' color='primary' onClick={() => onBillPlan(bi._id)}>取走</Button> : ''}
                </div>
            </div>}
        />)}
        <Step
            title='订单备注'
            status='wait'
            description={<div>
                <h4>{data.remark}</h4>
            </div>}
        />
        <Step
            title='骑手留言'
            status='wait'
            description={<TextArea
                style={{fontSize: "12px"}}
                placeholder='备注内容'
                maxLength={100}
                rows={2}
                showCount
            />}
        />
        <Step
            title='收货地址'
            status='wait'
            description={<div>
                <p>{data.address.detailInfo}</p>
                <a href={'tel:'+data.address.telNumber}><Button fill='outline' size='small' color='primary'>联系客户</Button></a>
            </div>}
        />
        <Step
            title='配送完成'
            status='wait'
            description={<div>
                <Button block fill='outline' color='primary' onClick={() => {
                    onOrderPlan(data._id)
                }}>送 达 目 的 地</Button>
            </div>}
        />
        <Step
            title='配送费结算'
            status='wait'
            description={<div>
                <h3>客户已签收[系统到账-5¥] </h3>
            </div>}
        />
    </Steps>
}

function OrderA(props) {
    let {data, upData} = props
    return <Card className="orderCardItems" title={`单号:${data.code}`} onClick={() => {
    }}>
        {data.billArr.map(bi => <List header={bi.store}>
            {bi.goods.map(goods => <List.Item prefix={<GoodsImg url={goods.img}/>}
                                              description={goods.text + '¥ ' + goods.count}
                                              children={goods.title} extra={goods.money + " ¥"}/>)}
        </List>)}
        <List header='收货信息'>
            <List.Item description={data.remark} children="备注"/>
            <List.Item description={data.address.detailInfo} children="收货地址"/>
        </List>
        <Button style={{marginTop: 20}} block color='primary' onClick={() => {
            DeliveryPUT(data._id).then(async r => {
                if (r.status == 200) {
                    upData()
                } else {
                    alert('订单被抢走了')
                }
            })
        }}>配送费: {data.deliveryMoney} ¥</Button>
    </Card>
}


function GoodsImg(props) {
    return <Image
        src={props.url}
        style={{borderRadius: 3}}
        fit='cover'
        width={30}
        height={30}
    />
}


