import {api} from "../utils";


// 骑手营收
export const DeliveryIncome = (user) => fetch(api+"user/income",{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
})

// 配送员看单
export const DeliveryGet= (school) => fetch(api+`order/delivery?school=${school}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

// 配送员接单
export const DeliveryPUT= (id) => fetch(api+`order/delivery/${id}`,{
    method:"PUT",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

//配送员在送单
export const DeliveryOrderGEG = () => fetch(api+'order/deliveryOrder',{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

//修改订单状态
export const OrderPlanPACT= (id,plan) => fetch(api+`order/plan/${id}/${plan}`,{
    method:"PATCH",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

export const DepositsOrderGET = ()=> fetch(api+'order/deposits',{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})