import {Outlet, useNavigate, useLocation, useSearchParams} from "react-router-dom";
import {
    EnvironmentOutline,
    LocationFill,
    UserOutline,
    TeamFill,
    HeartOutline,
    EditSFill, ShopbagOutline
} from 'antd-mobile-icons'
import {TabBar, Modal, Button, Form, Input} from "antd-mobile";
import {createContext, useEffect, useRef, useState} from "react";
import {ShoppingGETNumber, UserLogin, UserScope} from "../../model/user";
import {Login} from "../../component/login";
import {Store} from "../../model/store";

import {openWxLogin} from "../../utils";

export const User = createContext(null)

export default function () {
    const [searchParams, setSearchParams] = useSearchParams();
    const [scope,setScope] = useState(0); //状态吗
    const firstRenderRef = useRef(true);
    const [shoppingCount, setShoppingCount] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        if(firstRenderRef.current){
            let scope =  searchParams.get('scope')
            if (scope) {
                setScope(scope)
                UserScope(scope).then(async r => {
                    let data = await r.json()
                    console.log('加载区域',data)
                    document.title= data.name
                })
            }else {
                searchParams.set('scope',0)
                document.title = "默认区域"
            }
            firstRenderRef.current = false
            return
        }
    }, []);

    return <div>
        <User.Provider value={{shoppingCount,setShoppingCount}}>
            <Outlet/>
            <TabBar className='AppTabBar' onChange={(value) => {
                if(localStorage.getItem('token')){
                    navigate(value)
                }else {
                    openWxLogin()  //弹出微信登陆
                    // Login()  //本地测试登陆
                }
            }}>
                <TabBar.Item
                    key={ `/?scope=${scope}`}
                    icon={(active) => active ? <LocationFill/> : <EnvironmentOutline/>}
                    title={'首页'}
                />
                <TabBar.Item
                    key={`/shopping?scope=${scope}`}
                    icon={(active) => <ShopbagOutline/>}
                    title={'购物车'}
                    badge={shoppingCount?shoppingCount:''}
                />
                <TabBar.Item
                    key={`/me?scope=${scope}`}
                    icon={(active) => <UserOutline/>}
                    title={'我的'}
                />
            </TabBar>
        </User.Provider>

    </div>
}



