import {Button, Form, Input, List, Popup, Radio, Space, Switch, TextArea, Toast} from "antd-mobile";
import {useEffect, useRef, useState} from "react";
import {OrderIdGET, OrderPATCH, ShoppingPATCH} from "../../model/user";
import {json, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {SkeletonDIV, wxPay} from "../../utils";

export default function (props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState(1)
    const [address,setAddress] = useState({})
    const [delivery, setDelivery] = useState(0)
    const rest = useRef(true)
    const [form] = Form.useForm()
    const params = useParams();
    useEffect(() => {
        if (rest.current) {
            let order =  searchParams.get('order')
            OrderIdGET(order).then(async r => {
                let obj = await r.json()
                setValue(obj)
                console.log('预支付订单',order)
            })
            rest.current = false
            return;
        }
    }, [])
    return <div className="pay">
        {value._id ? <Form
            layout='horizontal'
            form={form}
            footer={
                <Button block type='submit' color='primary' size='large'>
                    支付 {delivery == '1' ? value.money + value.deliveryMoney : value.money}¥
                </Button>
            }
            onFinish={ (val) => {
                console.log(val,address.userName,val.delivery)
                if(!address.userName && val.delivery==1){
                    Toast.show({
                        content: '收货地址错误,只能上门自取',
                    })
                    navigate(-1)
                    return
                }

                val.address= address
                OrderPATCH(value._id,val).then(async r => {
                    if (r.status == 200) {
                        let sign = await wxPay(value)
                        console.log('支付签名', sign)
                        window.wx.chooseWXPay({
                            appId: sign.appId,
                            timestamp: sign.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            nonceStr: sign.nonceStr, // 支付签名随机串，不长于 32 位
                            package: sign.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: sign.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: sign.paySign, // 支付签名
                            success: function (res) {
                                // 支付成功后的回调函数
                                console.log('支付', res)
                                navigate(`/me?scope=${localStorage.getItem('scope')}`)
                            },
                            file:function (err){
                                Toast.show({
                                    content: '支付错误,返回首页',
                                })
                                navigate(`/?scope=${localStorage.getItem('scope')}`)
                            }
                        });
                    } else {
                        navigate(-1)
                    }
                })

            }}>
            {value.bill.map(item => <List header={item.store}>
                {item.goods.map((goods,i) =>
                    <List.Item key={'goods'+i} title={goods.title} description={goods.text + '¥ *' + goods.count}
                               extra={goods.money + "¥"}/>)}
            </List>)}


            <Form.Item name='remark' label='备注' help='备注所有店铺可见'>
                <TextArea
                    placeholder='备注所有店铺可见'
                    maxLength={100}
                    rows={2}
                    showCount
                />
            </Form.Item>
            <Form.Item
                name='delivery'
                label='配送方式'
                childElementPosition='right'
                rules={[{required: true, message: '请选择收货方式'}]}
            >
                <Radio.Group value={delivery}  onChange={(e) =>{
                    setDelivery(e)
                    if(e==1){
                        window.wx.openAddress({
                            success: function (res) {
                                setAddress(res)
                            }
                        });
                    }
                }}>
                    <Radio value='1'><span>配送上门 </span></Radio>
                    <Radio style={{marginLeft: '10px'}} value='2'><span>到店自取</span></Radio>
                </Radio.Group>
            </Form.Item>

            {delivery ==  1 && address.userName ? <Form.Item
                name='address'
                label='收货地址'
                childElementPosition='right'
                rules={[{required: false, message: '请选择收货地址'}]}
            >
                <span> {address.userName} - {address.telNumber} <br/> </span>
                <span> {address.detailInfo} </span>
            </Form.Item> : ''}
            {delivery == 1 ? <Form.Item
                name='delivery'
                label='配送费'
                childElementPosition='right'
            >
                <p> {value.bill.length}家店- {value.count}件商品- 共计 {value.deliveryMoney}¥ </p>
            </Form.Item> : ''}

        </Form> : <SkeletonDIV/>}


        <Popup visible={visible}
               onMaskClick={() => {
                   setVisible(false)
               }}
               onClose={() => {
                   setVisible(false)
               }}
               bodyStyle={{height: '40vh'}}>
            <p>收货地址</p>
        </Popup>
    </div>
}
