import {List} from "antd-mobile";
import React, {useEffect, useState} from "react";
import {DepositsOrderGET} from "../../model/delivery";


// "proxy": "http://localhost:3001",

export default function () {
    let [li, setLi] = useState([])
    useEffect(() => {
        DepositsOrderGET().then(async r => {
            let data = await r.json()
            console.log(data)
            setLi(data)
        })
    }, [])
    return <div>
        {li.length ? <List>
            {li.map(item=><List.Item description={<GoodsLi data={item.bill}/>} children={`单号:${item.code}`} extra={`${item.deposit?'}已到账':'未到账'}[${item.deliveryMoney}¥]`}/>)}
        </List> : ''}

    </div>
}

function GoodsLi(props) {
    const {data} = props
    return <div className={'orderGoodsLi'}>
        {data.map(item => <p>{item.store} * {item.count} </p>)}
        <p>终点:</p>
    </div>
}