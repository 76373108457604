import {api} from "../utils";

//店铺营收
export const StoreIncome = () => fetch(api+"store/income/"+localStorage.getItem('storeId'
),{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
})

//查询店铺
export const Store = () => fetch(api+"store",{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
})

// 修改店铺信息
export const StoreMsg = (obj) => fetch(api+"store/msg",{
    method: "PUT",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
})

// 上传商品
export const POSTGoods = (goods) => fetch(api+'goods',{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(goods)
})


// 获取上传图片授权
export const CosImg = async (file) => {
    let result = await fetch(api + 'cos/authorization', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            path: 'goodsImg',
            key: 'png'
        })
    }).then(r => r.json())
    let cosHost = result.cosHost;
    let cosKey = result.cosKey;
    let authorization = result.authorization;
    let securityToken = result.securityToken;
    let url = `https://${cosHost}/${cosKey}`
    return fetch(url,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Length': file.size, // Assuming 'data' is the file content as a Blob object
            'Authorization': authorization,
            'x-cos-security-token': securityToken,
            'Host': cosHost
        },
        body: file
    })
}

// 上传商品图片
export const POSTGoodsImg = (formData) => fetch(api+'uploads',{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body:formData
})

// 在售商品
export const GoodsLI = ()=> fetch(api+`goods/li/${localStorage.getItem('storeId')}`)

//其他状态商品
export const GoodsLIPlan = (plan)=> fetch(api+`goods/lis/${localStorage.getItem('storeId')}/${plan}`)

// 修改商品信息
export const GoodsPATCH = (id,goods) => fetch(api+`goods/${id}`,{
    method:"PATCH",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(goods)
})

// 删除订单
export const GoodsRM =(id) => fetch(api+`goods/${id}`,{
    method:"DELETE",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
})

// 店铺订单查看
export const StoreGet = (storeId,plan) => fetch(api+`store/order/${storeId}/${plan}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
})

// 修改分账账单信息
export const BillPlanPATCH = (billId,plan) =>fetch(api+`bill/plan/${billId}/${plan}`,{
    method:"PATCH",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

//店铺流水订单
export const BillLiPLanGet = (plan)=> fetch(api+`bill/li/${plan}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
})