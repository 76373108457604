import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import User from './pages/user'
import UserMe from './pages/user/me'
import UserHome from './pages/user/home'
import UserPay from './pages/user/pay'

import Store from "./pages/store";
import StoreHome from './pages/store/home'
import StoreMe from './pages/store/me'
import StoreOrder from './pages/store/order'
import StoreGoods from './pages/store/goods'
import Shopping from "./pages/user/shopping";
import UserOrder from "./pages/user/order";
import Delivery from "./pages/delivery";
import DeliveryHome from './pages/delivery/home';
import DeliverMe from './pages/delivery/me'
import DeliveryOrder from './pages/delivery/order'
import React, {useEffect, useRef} from "react";
import {wxLogin, wxReady} from "./utils";


// "proxy": "https://www.xldkeji.com",
//ssh-copy-id root@114.132.75.251

// 配置代理
// autossh -M 20000 -N -R 6000:localhost:3001 root@114.132.75.251



function App() {
    const firstRenderRef = useRef(true);
    useEffect(() => {
        if (firstRenderRef.current) {
            let url = window.location.href
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const scope = urlParams.get('scope')? urlParams.get('scope') : 0;

            if(code){
                console.log('微信登陆',code,scope)
                wxLogin(code,scope)
            }else {
                wxReady(url)
            }
            firstRenderRef.current = false;
            return
        }
    }, []);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<User/>}>
                    <Route index element={<UserHome/>}></Route>
                    <Route path="shopping" element={<Shopping/>}></Route>
                    <Route path="me" element={<UserMe/>}></Route>
                </Route>
                <Route path="pay" element={<UserPay/>}></Route>
                <Route path="order/:plan" element={<UserOrder/>}></Route>
                <Route path="/delivery" element={<Delivery/>}>
                    <Route index element={<DeliveryHome/>}></Route>
                    <Route path="me" element={<DeliverMe/>}></Route>
                    <Route path="*" element={<div>走错地方了</div>}></Route>
                </Route>
                <Route path="delivery_order" element={<DeliveryOrder/>}></Route>
                <Route path="/store" element={<Store/>}>
                    <Route index element={<StoreHome/>}></Route>
                    <Route path="goods" element={<StoreGoods/>}></Route>
                    <Route path="me" element={<StoreMe/>}></Route>
                </Route>
                <Route path="store_order" element={<StoreOrder/>}></Route>
                <Route path='*' element={<div>错误了</div>}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
