import {Avatar, Badge, List} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {GoodsLIPlan, Store, StoreIncome, StoreMsg} from "../../model/store";
import {UserScope} from "../../model/user";
import store from "./index";
const Height = window.innerHeight-60
export default function (){
    const navigate = useNavigate();
    const [me,setMe]= useState({})//
    const [code,setCode] = useState({})
    const [income,setIncome] = useState({})  //营收
    const firstRenderRef = useRef(true);
    useEffect( () => {
        if (firstRenderRef.current) {
            // 在售
            Store().then(async r => {
                let store = await r.json()
                setMe(store)
                console.log('店铺信息',store)
            })
            StoreIncome().then(async r => {
                setIncome(await r.json())
            })

            firstRenderRef.current = false
            return
        }
    },[])
    return <div className="My" style={{height: Height}}>
        <List mode='card' className='MyUser'>
            <List.Item
                style={{background: '#fcfcfc'}}
                prefix={<Badge
                    color='#87d068'
                    // content={Badge.dot}
                    content='店主'
                    style={{'--top': '90%', '--right': '80%',}}
                >
                    <Avatar style={{'--size': '64px', 'borderRadius': 60}} src={""}/>
                </Badge>}
                description={'位置：' + localStorage.getItem('storeScopeName')}
            >
                {me.name}
            </List.Item>
        </List>

        <div className='apph1'> 收银台</div>
        <div className='My_tabs'>
            <div className='My_tabs_item'>
                <span className='mt3'> {income.incomeA} <span className='mt2'>元</span> </span>
                <span className='mt1'>今日营收</span>
            </div>
            <div className='My_tabs_item'>
                <span className='mt3'>{income.incomeB} <span className='mt2'>元</span> </span>
                <span className='mt1'>外送收入</span>
            </div>
            <div className='My_tabs_item'>
                <span className='mt3'>{income.incomeC}  <span className='mt2'>元</span> </span>
                <span className='mt1'>自取收入</span>
            </div>
        </div>

        <List mode='card'>
            <List.Item  className='li_item'   onClick={()=>navigate('/store_order')}>
                销售流水
            </List.Item>
            <List.Item className='li_item' extra='' onClick={()=>{}}>
                店铺评价
            </List.Item>
        </List>

        <List mode='card'>
            <List.Item className='li_item' extra={me.machine_code?me.machine_code:'扫一扫'} onClick={()=>{
                window.wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        let result = JSON.parse(res.resultStr); // 当needResult 为 1 时，扫码返回的结果
                        StoreMsg({
                            id:me._id,
                            obj:{machine_code:result.machineCode}
                        }).then(async r => {
                            let store = await r.json()
                            Store().then(async r => {
                                let store = await r.json()
                                setMe(store)
                                console.log('店铺信息',store)
                            })
                            alert('添加:'+result.machineCode)
                        } )
                    }
                });
            }}>
                小票机{code.machineCode}
            </List.Item>
            <List.Item  className='li_item'  extra='1200'  onClick={()=>{}}>
                积分兑换
            </List.Item>
        </List>

        <List mode='card'>
            <List.Item className='li_item' onClick={()=>{}}>联系客服</List.Item>
        </List>

        <div className='footer'> Store_v0.0.1 </div>

    </div>
}