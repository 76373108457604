import {Button, Form, Input, Modal} from "antd-mobile";
import {UserLogin} from "../model/user";

export const Login = () => {
     let model = Modal.show({
        content: <Form layout='horizontal'
                       onFinish={(value)=>{
                           value.id = Number(value.id)
                           UserLogin(value).then(async r => {
                               let user = await r.json()
                               localStorage.setItem('token',user.token)
                               console.log('login',user)
                               model.close()
                           })
                       }}
                       footer={
                           <Button block color='primary' type='submit' size='large'>
                               登陆
                           </Button>
                       }
        >
            <Form.Header>请登陆,再继续操作..</Form.Header>
            <Form.Item label='用户名' name='id'>
                <Input placeholder='请输入用户名' clearable />
            </Form.Item>
            <Form.Item label='密码' name='password'>
                <Input placeholder='请输入密码' clearable type='password' />
            </Form.Item>

        </Form>,
        closeOnMaskClick: true,
    })
}